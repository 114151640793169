
@font-face {
  font-family: Gilroy;
  src: url("fonts/Radomir Tinkov - Gilroy-Regular.otf") format("opentype");
}

@font-face {
  font-family: GilroyBold;
  src: url("fonts/Radomir Tinkov - Gilroy-Bold.otf") format("opentype");
}

@font-face {
  font-family: GilroyExtraBold;
  src: url("fonts/Radomir Tinkov - Gilroy-ExtraBold.otf") format("opentype");
}

body {
  @media screen and (max-width:646px){
    background: #011e4c;
  }
}


.App {
  font-family: Gilroy;
  text-align: left;
  width: 100%;
  padding: 4%;
  @media screen and (max-width:646px){
    width: 100%;
    padding: 4%;
  }
}

.AppHeader {
  background: #011e4c;
  height: 100px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  font-size: 0;
  background-position-y: center;
  background-position-x: 20px;
  background-repeat: no-repeat;
  background-image: url("https://static.hartman.org.il/dev/images/logo-color.svg");
  @media screen and (max-width:646px){
    height: 60px;
    background-size: 40%;
  }
}


form {
  margin: 30px auto 0;
  max-width: 400px;
}

input {
  max-width: none;
  border: none;
  padding: 5px 10px;
  height: 40px;
  background-color: rgba(0,0,0,.1);
}

.donationAmountCont {
  text-align: center;
  font-size: 25px;
  font-family: "Gilroy";
  margin-top: 15px;
  @media screen and (max-width:646px){
    margin-top: 25px;
  }
}

.cardNotValid {
  margin-left: 20px;
  font-size: 14px;
  color: red;
  font-family: "Gilroy";
}

.failedPaymentStatus {
  text-align: center;
  font-size: 14px;
  color: red;
  font-family: "Gilroy";
}

.validCardName {
  text-align: center;
  font-size: 14px;
  color: red;
  font-family: "Gilroy";
  margin-left: 20px;
}

.successPayment {
  width: 100%;
  text-align: center;
  margin-top: 20%;
  font-family: 'Gilroy';
}


.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #011e4c;
  /* Dark Green */
  border-radius: 50%;
  width: 200px;
  height: 200px;
  animation: spinloader 2s linear infinite;
  margin-top: 20%;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width:646px){
    width: 150px;
    height: 150px;
    margin-top: 30%;
  }
}

.loader img {
  height: 120px;
  width: 120px;
  animation: spinlogo 2s linear infinite;
  @media screen and (max-width:646px){
    width: 60px;
    height: 60px;
  }
}

@keyframes spinloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinlogo {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}






.Loading {
  width: 100%;
  text-align: center;
  margin-top: 80%;
  font-size: 20px;
  color: #fff;
}

.LoadingContainer {
  background: rgba(1, 30, 76, 0.9);
  height: 100%;
  position: fixed;
  text-align: center;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}


.front {
  position: relative;
  transform: rotateY(0deg);
  background-color: red;
}
.back {
  position: relative;
  transform: rotateY(180deg);
  background-color: yellow;
}
.rccs__card {
  height: 182.873px;
  margin-top: 10px;
  margin-bottom: 10;
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
  width: 290px;
  @media screen and (max-width:646px){
    margin-top: 60px;
    display: none;
  }

  /* background: linear-gradient(
   25deg
, #986, #234); */
}

.front1 {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: red;
}

.back1 {
  transform: rotateY(360deg);
  margin-bottom: 30px !important;
  background-color: yellow;
}
.App-cards-list {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.App-cards-list > * {
  transform: scale(0.8);
  margin-bottom: 30px !important;
}

.back3 {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  background-image: url('6.jpeg');
}
/* rccs-signature-background: repeating-linear-gradient(
  0.1deg,
  #fff 20%,
  #fff 40%,
  #fea 40%,
  #fea 44%,
  #fff 44%
);
rccs-default-background: linear-gradient(25deg, #d32121, #178a63);
rccs-unknown-background: linear-gradient(25deg, rgb(160, 18, 101), #999);
rccs-background-transition: 0.5s;
rccs-animate-background: true; */



.rccs__card--unknown > div {
  background-image: url("6.jpeg");
}

.cardName {
  margin-bottom: 10px;
  font-size: 15px;
  color: #000;
  font-family: "Gilroy";
  width: 100%;
}

.cardOnNameTitle {
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 15px;
  color: #000;
  font-family: "GilroyBold";
  width: 100%;
}

.cardNumber {
  margin-bottom: 10px;
  font-size: 15px;
  color: #000;
  font-family: "Gilroy";
  width: 100%;
}

.cardNumberTitle {
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 15px;
  color: #000;
  font-family: "GilroyBold";
  width: 100%;
}

.expirationDate {
  margin-bottom: 10px;
  font-size: 15px;
  color: #000;
  font-family: "Gilroy";
  width: 80px;
}

.expirationDateTitle {
  margin-bottom: 10px;
  font-size: 15px;
  color: #000;
  width: 150px;
  font-family: "GilroyBold";
}

.cvv {
  margin-bottom: 10px;
  font-size: 15px;
  color: #000;
  font-family: "Gilroy";
  width: 80px;
}

.ccvTitle {
  margin-bottom: 5px;
  font-size: 15px;
  color: #000;
  width: 90px;
  font-family: "GilroyBold";
}

.ccDetails {
  margin-bottom: 10px;
  font-size: 15px;
  color: #000;
  font-family: "Gilroy";
  width: 100%;
}

.paymantSectionContainer{
  margin-top: 100px;
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  height: 450px;
  display: flex;
  flex-direction: row;
  background-color: white;
  @media screen and (max-width:646px){
    width: 100%;
    display: block;
    margin-top: 70px;
  }
}

.paymentFormContainer {
  margin-bottom: 30px;
  width: 45%;
  height: 450px;
  border: 1px solid rgba(110, 109, 109, 0.2);
  border-radius: 10px;
  box-shadow: 0px 16px 16px rgba(72, 72, 72, 0.2);
  padding: 2%;
  margin-left: auto;
  background-color: white;
  @media screen and (max-width:646px){
    width: 100%;
    height: 550px;
  }
}

.PaymentDetailsContainer {
  margin-bottom: 30px;
  margin-top: 50px;
  width: 55%;
  height: 350px;
  border: 1px solid rgba(110, 109, 109, 0.2);
  border-radius: 0px;
  box-shadow: 0px 16px 16px rgba(72, 72, 72, 0.2);
  padding: 3%;
  @media screen and (max-width:646px){
    display: none;
  }
}

.donationAmountLabel {
  font-size: 25px;
  color: #1c1c1c;
  font-family: "Gilroy";
  margin-bottom: 20px;
  @media screen and (max-width:646px){
    font-size: 20px;
  }
}

.donationAmountMobileCont {
  display: none;
  @media screen and (max-width:646px){
    display: block;
    margin-bottom: 20px;
    text-align: center;
  }
}

.formTitle {
  font-size: 25px;
  color: #1c1c1c;
  font-family: "Gilroy";
  margin-bottom: 20px;
  text-align: center;
}

.loaderMsg {
  font-size: 20px;
  color: #fff;
  font-family: "Gilroy";
  margin-top: 20px;
  text-align: center;
}

.paymentBtn {
  width: 100%;
  height: 40px;
  background-color: #011e4c;
  border: none;
  color: #fff;
  font-size: 18px;
  font-family: "Gilroy";
  margin-top: 10px;
  cursor: pointer;
  @media screen and (max-width:646px){
    margin-top: 50px;
  }
}
